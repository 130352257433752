import _ from 'lodash';
import queryString from 'querystring';
import ReactGa from 'react-ga';

export function handleDefaults(defaultObj, dynamicObj) {
  for (const key in dynamicObj) {
    defaultObj[key] = dynamicObj[key];
  }
  return defaultObj;
}

export const getValueFromUrl = (key) => {
  const queryParams = window.location.search;
  const query = queryParams.replace('?', '');
  const params = queryString.parse(query);
  return params[key];
};

export const getLearnosityResponse = (learnosityData) => {
  const allResponses = {};
  Object.entries(learnosityData).forEach(([key, data]) => {
    if (key === 'isAttempted' || key === 'isCorrect') return;
    const { value, type, apiVersion, revision, responses } = _.get(data, 'user_response') || {};
    allResponses[key] = {
      value,
      type,
      apiVersion,
      revision,
      responses
    };
  });
  return allResponses;
};

export const closeUpf = () => {
  if (window.history.length === 1) {
    window.close();
  } else {
    window.history.back();
  }
};

export const removeLearnosityKeypad = () => {
  // remove learnosity keypad from DOM as unmounting DUCS will not get rid of it
  const learnosityKeypad = window.document.querySelector('.lrn_absolute_keyboard_parent');
  if (learnosityKeypad) {
    window.document.body.removeChild(learnosityKeypad);
  }
};

export const leaveMle = (category) => {
  ReactGa.event({
    category,
    action: 'Leave Assessments to go back to Student Console',
    label: 'Assessments student interaction'
  });
  if (window.history.length === 1) {
    window.close();
  } else {
    window.history.back();
  }
};
