import { useState } from 'react';
import { Timer } from 'easytimer.js';

const timer = new Timer();

// eslint-disable-next-line import/prefer-default-export
export const useTimer = ({ timeLeft, isCompleted }) => {
  const [tick, setTick] = useState('');

  timer.start({
    precision: 'seconds',
    startValues: { seconds: timeLeft },
    countdown: true
  });
  timer.addEventListener('secondsUpdated', () => {
    let str = timer.getTimeValues().toString();
    if (timer.getTimeValues().hours === 0) {
      str = str.slice(3, str.length);
    }
    setTick(str);
  });

  if (isCompleted) {
    timer.removeEventListener('secondsUpdated');
    timer.stop();
  }

  return [isCompleted ? '00:00' : tick];
};
