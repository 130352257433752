import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import css from './progress-info.scss';
import { countAnswered } from './progress-info-utils';
import TimeArea from '../time-area/time-area';
import { durationTypeCssClass } from '../../helpers/constants';

const ProgressInfo = ({
  className
}) => {
  const {
    durationType,
    questions
  } = useSelector(state => state.mleReducer);
  const answered = countAnswered(questions);
  const totalQuestions = questions.length;

  const calculatePercent = () => (answered / totalQuestions) * 100;

  return (
    <div
      className={classNames(
        css['progress-info'],
        className,
        css[durationTypeCssClass[durationType]]
      )}
    >
      <TimeArea />
      <div className={css['progress-text']}>
        <span>{`${answered}/${totalQuestions}`}</span>&nbsp;
        <span className={css.answered}><Trans>Answered</Trans></span>
      </div>
      <div className={css['progress-bar']}>
        <div
          style={{
            width: `Calc(${calculatePercent()}%)`
          }}
          className={css['inner-bar']}
        />
      </div>
    </div>
  );
};

ProgressInfo.propTypes = {
  className: PropTypes.string
};

export default ProgressInfo;
