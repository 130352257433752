import BasicResources from './basic-resources';
import { resourceEndPoints } from './constants';

class Resources {
  static createResources(parentEnv) {
    if (parentEnv) {
      Resources.env = parentEnv;
    }

    const env = Resources.env;

    const BASE_URL = window._env_.AssessmentsMleGateway;

    Object.entries(resourceEndPoints).forEach(([key, value]) => {
      Resources[key] = Resources.env
        .resource(key)
        .baseUrl(BASE_URL)
        .service(value);
    });

    Resources.getConfigurationItem = async key => env.getConfigurationItem(key);
  }

  static initialise(envName, credentials) {
    Resources.env = BasicResources.initialise(envName, credentials);

    Resources.createResources();

    return Resources.env;
  }
}

export default Resources;

