import React from 'react';
import { useSelector } from 'react-redux';
import css from './questions.scss';
import Question from '../question/question';

const Questions = () => {
  const { questions } = useSelector(state => state.mleReducer);

  return (
    <div className={css.questions}>
      {
        questions.map((question, index) => (
          <Question
            question={question}
            index={index}
            key={question.girlId}
          />
        ))
      }
    </div>
  );
};

export default Questions;
