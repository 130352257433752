export const LOCALE_DEFAULT = 'en-AU';

export const resourceEndPoints = {
  fetchAssignmentDetails: '/assignments/{assignmentId}',
  fetchActivityMeta: '/assignments/{assignmentId}/questions/{questionId}/locales/{locale}',
  markActivity: '/assignments/{assignmentId}/questions/{questionId}/submit',
  flagActivity: '/assignments/{assignmentId}/questions/{questionId}/flag',
  completeAssignment: '/assignments/{assignmentId}/complete'
};

export const LANDING_SPINNER = 'LANDING_SPINNER';
export const ACTIVITY_META_SPINNER = 'ACTIVITY_META_SPINNER';
export const LEARNOSITY_SPINNER = 'LEARNOSITY_SPINNER';
export const COMPLETE_ASSIGNMENT_SPINNER = 'COMPLETE_ASSIGNMENT_SPINNER';

export const UNATTEMPTED = 'UNATTEMPTED';
export const ATTEMPTED = 'ATTEMPTED';
export const REVIEW_CORRECT = 'REVIEW_CORRECT';
export const REVIEW_INCORRECT = 'REVIEW_INCORRECT';
export const REVIEW_UNATTEMPTED = 'REVIEW_UNATTEMPTED';

// index 0 is duration whole
// index 1 is duration question
export const durationTypeCssClass = [
  'whole',
  'question'
];
export const DURATION_TYPE_WHOLE = 0;
export const DURATION_TYPE_QUESTION = 1;
export const TIMED_QUESTION_END_MARKER = '--:--';
