/* global document */
import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';

const DummyOff = () => {
  const { loadedInitialActivity } = useSelector(state => state.activityReducer);

  useEffect(() => {
    const dummy = document.querySelector('.dummy-placeholder');

    if (loadedInitialActivity) {
      dummy.parentElement.removeChild(dummy);
    }
  }, [loadedInitialActivity]);

  return <Fragment />;
};

export default DummyOff;
