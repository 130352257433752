exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".question-icons---3pBIs{display:flex}.question-icons---3pBIs .pencil---WCPHg,.question-icons---3pBIs .tick---o8YFt,.question-icons---3pBIs .cross---2JBso,.question-icons---3pBIs .dash---1uPZo,.question-icons---3pBIs .empty-column---2jGY9{width:16px;display:block}.question-icons---3pBIs .flag---2X-Qt,.question-icons---3pBIs .empty-flag---2U3_p{width:16px;margin-left:4px}", ""]);

// exports
exports.locals = {
	"question-icons": "question-icons---3pBIs",
	"pencil": "pencil---WCPHg",
	"tick": "tick---o8YFt",
	"cross": "cross---2JBso",
	"dash": "dash---1uPZo",
	"empty-column": "empty-column---2jGY9",
	"flag": "flag---2X-Qt",
	"empty-flag": "empty-flag---2U3_p"
};