import _ from 'lodash';

export const DUCS_LOADING = 'DUCS_LOADING';
export const DUCS_LOADED = 'DUCS_LOADED';
export const DUCS_CHANGED = 'DUCS_CHANGED';
export const DUCS_ERROR = 'DUCS_ERROR';
export const DUCS_RESUME = 'resume';
export const DUCS_REVIEW = 'review';

export const ACTIVITY_QUESTION_CHANGED = 'ACTIVITY_QUESTION_CHANGED';
export const ERROR_EVENT_DISPATCHED = 'ERROR_EVENT_DISPATCHED';
export const PAGE_LOADED = 'PAGE_LOADED';
export const ACTIVITY_QUESTION_MARKED = 'ACTIVITY_QUESTION_MARKED';

export const subscribeToEvents = (id, setEvent) => {
  window.DUCSApplication.subscribe(id, PAGE_LOADED, () => {
    setEvent({ type: DUCS_LOADED });
  });
  window.DUCSApplication.subscribe(id, ACTIVITY_QUESTION_CHANGED, ({ params }) => {
    setEvent({ type: DUCS_CHANGED, response: params });
  }, { onlyCurrentData: true });
  window.DUCSApplication.subscribe(id, ERROR_EVENT_DISPATCHED, () => {
    setEvent({ type: DUCS_ERROR });
  });
};

export const formatResponses = (userResponse) => {
  const responses = {};

  if (!_.isEmpty(userResponse)) {
    Object.keys(userResponse).forEach((key) => {
      if (key === 'isAttempted' || key === 'isCorrect' || key === 'total') return;
      const { value, type, apiVersion, revision } = _.get(userResponse[key], 'user_response') || {};
      responses[key] = {
        value,
        type,
        apiVersion,
        revision,
        responses: _.get(userResponse[key], 'user_response.responses', []),
        updatedFormat: true
      };
    });
  }
  return responses;
};
