import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import _ from 'lodash';
import {
  markActivity,
  setNextQuestion
} from '../activity/activity-actions';
import css from './time-ticker-question.scss';
import { useTimer } from './time-ticker-question-hooks';
import { handIn } from '../mle/mle-actions';
import { ModalGeneric } from '../../components/modal-content';
import { constructModal, activateModal } from '../modal/modal-actions';
import { useLocalisation } from '../../helpers/i18n/use-localisation';
import { getLearnosityResponse, getValueFromUrl } from '../../helpers/utils';
import { TIMED_QUESTION_END_MARKER } from '../../helpers/constants';

const TimeTickerQuestion = ({
  className
}) => {
  const {
    timeLeftInSeconds,
    questions,
    isCompleted,
    questionIndex
  } = useSelector(state => state.mleReducer);
  const {
    isLoading,
    userResponse,
    activityMeta
  } = useSelector(state => state.activityReducer);
  const dispatch = useDispatch();
  const { i18n } = useLocalisation();

  const [tick, setTick] = useTimer(timeLeftInSeconds);

  const expirationModal = () => {
    dispatch(handIn());
    const modalData = {
      subHeader: i18n._(t`Great work!`),
      paragraph: i18n._(t`Your assessment has finished and been handed in.`),
      buttons: [
        {
          text: i18n._(t`OK`),
          callBack: () => dispatch(activateModal(false)),
          size: 'large'
        }
      ]
    };

    dispatch(constructModal({
      activate: true,
      content: <ModalGeneric modalData={modalData} />,
      canvasOnClick: false
    }));
  };

  const activityParams = {
    assignmentId: getValueFromUrl('assignmentId'),
    isCorrect: userResponse.isCorrect,
    learnosityData: JSON.stringify(getLearnosityResponse(userResponse)),
    questionId: _.get(activityMeta, 'girlId') || ''
  };

  useEffect(() => {
    if (tick === TIMED_QUESTION_END_MARKER && questionIndex === questions.length - 1) {
      dispatch(markActivity({
        ...activityParams,
        promiseResolved: () => expirationModal()
      }));
    } else if (tick === TIMED_QUESTION_END_MARKER) {
      dispatch(markActivity({
        ...activityParams,
        promiseResolved: () => dispatch(setNextQuestion({
          currentIndex: questionIndex,
          totalQuestions: questions.length
        }))
      }));
    }
  }, [tick]);

  useEffect(() => {
    if (!isLoading && !isCompleted) {
      setTick(timeLeftInSeconds);
    }
  }, [isLoading]);

  return (
    <div className={classNames(css['time-ticker'], className)}>
      {isCompleted ? '00:00' : tick}
    </div>
  );
};

TimeTickerQuestion.propTypes = {
  className: PropTypes.string
};

export default TimeTickerQuestion;
