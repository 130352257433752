exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "@keyframes fade-in---wZkfG{0%{opacity:0}100%{opacity:1}}.questions---3VH79{height:calc(100% - 110px);overflow-y:auto}@supports(-webkit-touch-callout: none){.questions---3VH79{position:absolute;width:100%;top:110px}}", ""]);

// exports
exports.locals = {
	"questions": "questions---3VH79",
	"fade-in": "fade-in---wZkfG"
};