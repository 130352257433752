import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Trans, t } from '@lingui/macro';
import ReactGa from 'react-ga';
import _ from 'lodash';
import classNames from 'classnames';
import css from './footer.scss';
import { BtnText } from '../../components/buttons';
import {
  markActivity,
  setIsMarked,
  setNextQuestion,
  setActivityResponse
} from '../activity/activity-actions';
import { isMarkActivityDisabled } from '../activity/activity-utils';
import InfoFeedback from '../info-feedback/info-feedback';
import Info from '../info/info';
import { getLearnosityResponse, getValueFromUrl } from '../../helpers/utils';
import { ModalGeneric } from '../../components/modal-content';
import { constructModal, activateModal } from '../modal/modal-actions';
import { useLocalisation } from '../../helpers/i18n/use-localisation';
import { handIn } from '../mle/mle-actions';
import { durationTypeCssClass, DURATION_TYPE_WHOLE } from '../../helpers/constants';

const Footer = () => {
  const {
    isMarked,
    isLoading,
    userResponse,
    activityMeta,
    activityIndex
  } = useSelector(state => state.activityReducer);
  const {
    questions,
    isCompleted,
    durationType
  } = useSelector(state => state.mleReducer || {});
  const dispatch = useDispatch();
  const { i18n } = useLocalisation();

  const handInModal = () => {
    const modalData = {
      subHeader: i18n._(t`Have you reviewed your answers?`),
      paragraph: i18n._(t`You can open this assessment to review and edit answers until the time is up.
      When the time is up your assessment will be handed in.`),
      buttons: [
        {
          text: i18n._(t`Go back`),
          callBack: () => dispatch(activateModal(false)),
          size: 'large',
          type: 'secondary'
        },
        {
          text: i18n._(t`I'm ready!`),
          callBack: () => dispatch(handIn()),
          size: 'large'
        }
      ]
    };
    dispatch(constructModal({
      activate: true,
      content: <ModalGeneric modalData={modalData} />,
      canvasOnClick: false
    }));
  };

  const handleSaveAnswer = () => {
    dispatch(markActivity({
      assignmentId: getValueFromUrl('assignmentId'),
      questionId: activityMeta.girlId,
      isCorrect: userResponse.isCorrect,
      learnosityData: JSON.stringify(getLearnosityResponse(userResponse)),
      promiseResolved: () => dispatch(setNextQuestion({
        currentIndex: activityIndex,
        totalQuestions: questions.length
      }))
    }));
    ReactGa.event({
      category: 'Button - Save answer',
      action: 'Mark the question',
      label: 'Student interaction'
    });
  };

  const handleChangeAnswer = () => {
    dispatch(setIsMarked(false));
    dispatch(setActivityResponse(''));
    dispatch(markActivity({
      assignmentId: getValueFromUrl('assignmentId'),
      questionId: activityMeta.girlId,
      isCorrect: false,
      learnosityData: ''
    }));
    ReactGa.event({
      category: 'Button - Change Answer',
      action: 'Change answer',
      label: 'Student interaction'
    });
  };

  const keyUpHandler = (event) => {
    if (userResponse && event.key === 'Enter' && !isMarkActivityDisabled({ isLoading, userResponse })) {
      handleSaveAnswer();
    }
  };

  useEffect(() => {
    if (durationType === DURATION_TYPE_WHOLE) {
      window.addEventListener('keyup', keyUpHandler);
    }
    return () => {
      if (durationType === DURATION_TYPE_WHOLE) {
        window.removeEventListener('keyup', keyUpHandler);
      }
    };
  }, [userResponse, isLoading]);

  return (
    <div className={classNames(css.footer, css[durationTypeCssClass[durationType]])}>
      <div className={css['activity-side']}>
        <div className={css['misc-container']}>
          <Info />
          <InfoFeedback
            className={css['info-feedback-margin']}
            girlId={_.get(activityMeta, 'girlId') || ''}
          />
        </div>
        <div className={css['btn-cont']}>
          {
            isMarked ?
              (
                <BtnText
                  className={css.btn}
                  callBack={handleChangeAnswer}
                  isDisabled={isLoading || isCompleted}
                  type="secondary"
                >
                  <Trans>Change answer</Trans>
                </BtnText>
              ) : (
                <BtnText
                  className={css.btn}
                  callBack={handleSaveAnswer}
                  isDisabled={isMarkActivityDisabled({ isLoading, userResponse }) || isCompleted}
                  type="secondary"
                >
                  <Trans>Save answer</Trans>
                </BtnText>
              )
          }
        </div>
      </div>
      <div className={css['progress-side']}>
        <BtnText
          className={css.btn}
          callBack={handInModal}
          isDisabled={isLoading || isCompleted}
        >
          <Trans>Hand in</Trans>
        </BtnText>
      </div>
    </div>
  );
};

export default Footer;

