import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BtnText } from '../../buttons';
import css from './modal-generic.scss';

const ModalGeneric = ({ modalData: { header, subHeader, paragraph, buttons } }) => (
  <div className={css['modal-generic']}>
    {header && <h2>{header}</h2>}
    {subHeader && <h4>{subHeader}</h4>}
    {paragraph && <div>{paragraph}</div>}
    {buttons &&
      <div className={classNames(css['modal-buttons'], { [css.multiple]: buttons.length > 1 })}>
        {buttons.map(btnData => (<BtnText
          className={css.button}
          callBack={btnData.callBack}
          key={btnData.text}
          size={btnData.size}
          type={btnData.type}
        >
          {btnData.text}
        </BtnText>)
        )}
      </div>
    }
  </div>
);

ModalGeneric.propTypes = {
  modalData: PropTypes.object
};

export default ModalGeneric;
