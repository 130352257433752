exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "@keyframes fade-in---3i-Vo{0%{opacity:0}100%{opacity:1}}.btn---2im-P{outline:none;border:none;border-bottom:1px solid #a6e3e4;display:flex;height:44px;background-color:rgba(0,0,0,0);width:100%;align-items:center;padding-left:10px}.btn---2im-P .text---29AMI{text-align:left;width:90px}.btn---2im-P .text---29AMI .question---1oRkL,.btn---2im-P .text---29AMI .index---1TO4x{font-size:13px;color:#fff}.btn---2im-P:disabled{background-color:#fff}.btn---2im-P:disabled .text---29AMI .question---1oRkL,.btn---2im-P:disabled .text---29AMI .index---1TO4x{color:#5f5f5f}.btn---2im-P:disabled.remain-blue---yNmGQ{background-color:rgba(0,0,0,0)}.btn---2im-P:disabled.remain-blue---yNmGQ .text---29AMI .question---1oRkL,.btn---2im-P:disabled.remain-blue---yNmGQ .text---29AMI .index---1TO4x{color:#fff}@media only screen and (max-width: 800px){.btn---2im-P .text---29AMI{width:35px}.btn---2im-P .text---29AMI .question---1oRkL .inner-question---1VJQ7{display:none}.btn---2im-P .text---29AMI .question---1oRkL::before{content:\"Q\"}}", ""]);

// exports
exports.locals = {
	"btn": "btn---2im-P",
	"text": "text---29AMI",
	"question": "question---1oRkL",
	"index": "index---1TO4x",
	"remain-blue": "remain-blue---yNmGQ",
	"inner-question": "inner-question---1VJQ7",
	"fade-in": "fade-in---3i-Vo"
};