exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "@keyframes fade-in---2h6wx{0%{opacity:0}100%{opacity:1}}.header---1xVMn{animation:fade-in---2h6wx 1s ease-in;color:#fff;padding:14px 40px 14px 16px;background-color:#3477c7;display:flex;position:relative;justify-content:space-between;flex-shrink:0}.header---1xVMn .left---oYOSq{display:flex;flex-direction:row;overflow:hidden;min-width:125px;padding-top:5px}.header---1xVMn .left---oYOSq .back---3VQFE{padding:0;margin-right:15px;height:52px;width:52px}.header---1xVMn .left---oYOSq .logo---2NVZB{height:calc(2*100px/3);width:115px;min-width:115px;padding:0}.header---1xVMn .left---oYOSq .logo---2NVZB svg{transform:scale(0.65) translate(-25%, -25%)}.header---1xVMn .left---oYOSq p{margin:10px 0 0 10px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.header---1xVMn .right---3VRsP{display:flex;flex-direction:row;justify-content:flex-end;overflow:hidden}.header---1xVMn .no-collapse---pjwgV{flex-shrink:0}.modal-bold---3rHwd{font-family:\"Muli-Bold\"}", ""]);

// exports
exports.locals = {
	"header": "header---1xVMn",
	"fade-in": "fade-in---2h6wx",
	"left": "left---oYOSq",
	"back": "back---3VQFE",
	"logo": "logo---2NVZB",
	"right": "right---3VRsP",
	"no-collapse": "no-collapse---pjwgV",
	"modal-bold": "modal-bold---3rHwd"
};