
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t, Trans } from '@lingui/macro';
import { constructModal, activateModal } from '../modal/modal-actions';
import { ModalGeneric } from '../../components/modal-content';
import { useLocalisation } from '../../helpers/i18n/use-localisation';
import { leaveMle } from '../../helpers/utils';
import css from './header.scss';

// eslint-disable-next-line import/prefer-default-export
export const useBackModal = () => {
  const { i18n } = useLocalisation();
  const dispatch = useDispatch();
  const { durationType } = useSelector(state => state.mleReducer);

  const backModal = () => {
    const modals = [
      {
        subHeader: i18n._(t`Your answers will be saved.`),
        // eslint-disable-next-line max-len
        paragraph: `${i18n._(t`You can open this assessment to review and edit answers until the time is up. When the time is up your assessment 
        will be handed in.`)}`,
        buttons: [
          {
            text: i18n._(t`Cancel`),
            callBack: () => dispatch(activateModal(false)),
            size: 'large',
            type: 'secondary'
          },
          {
            text: i18n._(t`Leave`),
            callBack: () => leaveMle('Button - back'),
            size: 'large'
          }
        ]
      },
      {
        subHeader: i18n._(t`Are you sure you want to leave?`),
        paragraph: (
          <Fragment>
            <p key="para1">
              <Trans key="trans1">
                The timer on the assessment questions will continue after
                you leave and any unanswered questions will be marked as
              </Trans>&nbsp;
              <span className={css['modal-bold']} key="span1">
                <Trans key="trans2">
                  not attempted
                </Trans>
              </span>.
            </p>
            <p key="para2">
              <Trans key="trans3">
                Still want to leave?
              </Trans>
            </p>
          </Fragment>
        ),
        buttons: [
          {
            text: i18n._(t`Cancel`),
            callBack: () => dispatch(activateModal(false)),
            size: 'large',
            type: 'secondary'
          },
          {
            text: i18n._(t`Leave`),
            callBack: () => leaveMle('Button - back'),
            size: 'large'
          }
        ]
      }
    ];

    dispatch(constructModal({
      activate: true,
      content: <ModalGeneric modalData={modals[durationType]} />,
      canvasOnClick: false
    }));
  };

  return [backModal];
};
