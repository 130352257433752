import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import FlagUnactivated from '../../assets/images/flag.svg';
import FlagActivated from '../../assets/images/flag-activated.svg';
import { BtnIcon } from '../../components/buttons';
import css from './flag-toggle.scss';
import { flagActivity } from '../activity/activity-actions';
import { getValueFromUrl } from '../../helpers/utils';
import { DURATION_TYPE_QUESTION } from '../../helpers/constants';

const FlagToggle = ({ className }) => {
  const { questions, durationType } = useSelector(state => state.mleReducer);
  const { activityIndex } = useSelector(state => state.activityReducer);
  const { girlId } = useSelector(state => state.activityReducer.activityMeta);
  const dispatch = useDispatch();

  const handleToggle = () => {
    dispatch(flagActivity({
      assignmentId: getValueFromUrl('assignmentId'),
      questionId: girlId,
      isFlagged: !questions[activityIndex].isFlagged
    }));
  };

  if (durationType === DURATION_TYPE_QUESTION) {
    return null;
  }

  return (
    <Fragment>
      {
        !questions[activityIndex].isFlagged &&
        <BtnIcon
          className={classNames(css['flag-toggle'], className)}
          icon={FlagUnactivated}
          callBack={handleToggle}
        />
      }
      {
        questions[activityIndex].isFlagged &&
        <BtnIcon
          className={classNames(css['flag-toggle'], className)}
          icon={FlagActivated}
          callBack={handleToggle}
        />
      }
    </Fragment>
  );
};

FlagToggle.propTypes = {
  className: PropTypes.string
};

export default FlagToggle;
