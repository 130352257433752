import { combineReducers } from 'redux';
import modalReducer from '../modal/modal-reducer';
import errorHandlingReducer from '../error-handling/error-handling-reducer';
import mleReducer from '../mle/mle-reducer';
import spinnerReducer from '../mle/spinner-reducer';
import activityReducer from '../activity/activity-reducer';
import ducsReducer from '../ducs/ducs-reducer';
import detectDevToolsReducer from '../devtools-detect/devtools-detect-reducer';

const CombinedReducers = combineReducers({
  modalReducer,
  errorHandlingReducer,
  mleReducer,
  spinnerReducer,
  activityReducer,
  ducsReducer,
  detectDevToolsReducer
});

export default CombinedReducers;
