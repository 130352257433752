import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import css from './time-ticker.scss';
import { useTimer } from './time-ticker-hooks';
import { handIn } from '../mle/mle-actions';
import { ModalGeneric } from '../../components/modal-content';
import { constructModal, activateModal } from '../modal/modal-actions';
import { useLocalisation } from '../../helpers/i18n/use-localisation';

const TimeTicker = ({
  className
}) => {
  const {
    timeLeftInSeconds,
    isCompleted
  } = useSelector(state => state.mleReducer);
  const dispatch = useDispatch();
  const { i18n } = useLocalisation();

  const [tick] = useTimer({ timeLeft: timeLeftInSeconds, isCompleted });

  const expirationModal = () => {
    dispatch(handIn());
    const modalData = {
      subHeader: i18n._(t`Time is up.`),
      paragraph: i18n._(t`Your assessment has been handed in.`),
      buttons: [
        {
          text: i18n._(t`OK`),
          callBack: () => dispatch(activateModal(false)),
          size: 'large'
        }
      ]
    };

    dispatch(constructModal({
      activate: true,
      content: <ModalGeneric modalData={modalData} />,
      canvasOnClick: false
    }));
  };

  useEffect(() => {
    if (tick === '00:00' && !isCompleted) {
      expirationModal();
    }
  }, [tick]);

  return (
    <div className={classNames(css['time-ticker'], className)}>
      {tick}
    </div>
  );
};

TimeTicker.propTypes = {
  className: PropTypes.string
};

export default TimeTicker;
