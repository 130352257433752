import { useDispatch, useSelector } from 'react-redux';
import { handleNext, setNextQuestion } from './activity-actions';
import { DURATION_TYPE_WHOLE } from '../../helpers/constants';

// eslint-disable-next-line import/prefer-default-export
export const useHandleNextFunc = () => {
  const dispatch = useDispatch();
  const { questions, questionIndex, durationType, isCompleted } = useSelector(state => state.mleReducer);
  const { activityIndex, loadedInitialActivity } = useSelector(state => state.activityReducer);

  const handleNextFunc = (ducsRef) => {
    const params = {
      questions,
      loadedInitialActivity,
      ducsRef
    };

    const tasks = [
      () => {
        dispatch(handleNext({
          ...params,
          activityIndex: activityIndex > questions.length - 1 ? 0 : activityIndex
        }));
      },
      () => {
        dispatch(setNextQuestion({ index: questionIndex }));
        dispatch(handleNext({
          ...params,
          activityIndex: questionIndex > questions.length - 1 ? 0 : questionIndex
        }));
      }
    ];
    if (isCompleted) {
      if (activityIndex === questions.length) {
        dispatch(setNextQuestion({ index: 0 }));
      }
      tasks[DURATION_TYPE_WHOLE]();
    } else {
      tasks[durationType]();
    }
  };

  return [handleNextFunc];
};
