import React, { useState } from 'react';
import { t } from '@lingui/macro';
import classNames from 'classnames';
import css from './time-area.scss';
import { useLocalisation } from '../../helpers/i18n/use-localisation';
import { useAssets } from './time-area-hooks';

const TimeArea = () => {
  const { i18n } = useLocalisation();
  const [timeArea, setTimeArea] = useState(null);
  const [assets] = useAssets();

  const { TimeTicker, timerIcon, arrow } = assets();

  const handleTimeArea = () => {
    const toggle = timeArea === 'min' ? null : 'min';
    setTimeArea(toggle);
  };

  return (
    <div
      className={classNames(css['time-area'], timeArea && css[timeArea])}
      onClick={handleTimeArea}
      role="presentation"
    >
      <img
        className={css['timer-icon']}
        src={timerIcon}
        alt={i18n._(t`timer icon`)}
      />
      <TimeTicker
        className={css['time-ticker']}
      />
      <img
        className={css.arrow}
        src={arrow}
        alt={i18n._(t`arrow`)}
      />
    </div>
  );
};

export default TimeArea;
