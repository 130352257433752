import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import css from './question.scss';
import { setNextQuestion } from '../activity/activity-actions';
import QuestionIcons from '../question-icons/question-icons';
import { DURATION_TYPE_QUESTION } from '../../helpers/constants';

const Question = ({ question, index }) => {
  const { activityIndex, isLoading } = useSelector(state => state.activityReducer);
  const { isCompleted, durationType } = useSelector(state => state.mleReducer);
  const dispatch = useDispatch();
  const quesRef = useRef(null);

  const handleNextQuestion = () => {
    dispatch(setNextQuestion({ index }));
  };

  useEffect(() => {
    if (activityIndex === index && quesRef.current) {
      setTimeout(() => {
        quesRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        });
      });
    }
  }, [activityIndex]);

  return (
    <button
      className={classNames(css.btn, activityIndex !== index && css['remain-blue'])}
      onClick={() => handleNextQuestion()}
      type="button"
      disabled={
        activityIndex === index ||
        isLoading ||
        (durationType === DURATION_TYPE_QUESTION && !isCompleted)
      }
      ref={quesRef}
    >
      <div
        className={css.text}
      >
        <span className={css.question}>
          <span className={css['inner-question']}>Question</span>
        </span>
        &nbsp;
        <span className={css.index}>{index + 1}</span>
      </div>
      <QuestionIcons
        isCompleted={isCompleted}
        question={question}
      />
    </button>
  );
};

Question.propTypes = {
  question: PropTypes.object,
  index: PropTypes.number
};

export default Question;
