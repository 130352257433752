import Resources from '../../helpers/resources';
import { activateError } from '../error-handling/error-handling-actions';
import { LANDING_SPINNER, COMPLETE_ASSIGNMENT_SPINNER } from '../../helpers/constants';
import {
  completeAssignment,
  startLoadingActivity,
  stopLoadingActivity
} from '../activity/activity-actions';
import { getValueFromUrl } from '../../helpers/utils';
import { activateModal } from '../modal/modal-actions';

export const ASSIGNMENT_DETAILS_FETCHED = 'ASSIGNMENT_DETAILS_FETCHED';
export const COMPONENT_ENABLED_SPINNER = 'COMPONENT_ENABLED_SPINNER';
export const COMPONENT_DISABLED_SPINNER = 'COMPONENT_DISABLED_SPINNER';

export const assignmentDetailsFetched = payload => ({
  type: ASSIGNMENT_DETAILS_FETCHED,
  payload
});

export const enableSpinner = item => ({
  type: COMPONENT_ENABLED_SPINNER,
  item
});

export const disableSpinner = item => ({
  type: COMPONENT_DISABLED_SPINNER,
  item
});

export const fetchAssignmentDetails = assignmentId => async (dispatch) => {
  dispatch(enableSpinner(LANDING_SPINNER));
  try {
    const assignmentDetailsResponse = await Resources.fetchAssignmentDetails
      .get({ assignmentId })
      .send();

    const { status, data } = assignmentDetailsResponse;

    if (status === 200 || status === 204) {
      dispatch(assignmentDetailsFetched(data));
    }
    dispatch(disableSpinner(LANDING_SPINNER));
  } catch (e) {
    dispatch(activateError('network error'));
    dispatch(disableSpinner(LANDING_SPINNER));
  }
};

export const handIn = () => (dispatch) => {
  dispatch(activateModal(false));
  dispatch(completeAssignment({
    assignmentId: getValueFromUrl('assignmentId'),
    startFetch: () => {
      dispatch(startLoadingActivity());
      dispatch(enableSpinner(COMPLETE_ASSIGNMENT_SPINNER));
    },
    endFetch: () => {
      dispatch(stopLoadingActivity());
      dispatch(disableSpinner(COMPLETE_ASSIGNMENT_SPINNER));
    }
  }));
};
