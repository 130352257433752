import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setDevToolsStatus } from './devtools-detect-actions';
import { getValueFromUrl } from '../../helpers/utils';
import { resetActivity } from '../activity/activity-actions';

const DetectDevtools = () => {
  const dispatch = useDispatch();
  let isDevToolsOpened = true;
  let oldDevToolsOpenState = true;

  const dummyElement = window.document.createElement('span');
  Object.defineProperty(dummyElement, 'id', {
    get: () => {
      isDevToolsOpened = true;
    }
  });

  const checkIfDevToolsIsOpen = () => {
    isDevToolsOpened = false;
    // do not remove the console statement below, it does the trick of detecting the devtools
    console.debug(dummyElement); // eslint-disable-line no-console
    if (oldDevToolsOpenState !== isDevToolsOpened) {
      oldDevToolsOpenState = isDevToolsOpened;
      dispatch(setDevToolsStatus(isDevToolsOpened));
      dispatch(resetActivity());
    }
    window.requestAnimationFrame(checkIfDevToolsIsOpen);
  };

  const startDetection = async () => {
    const cheatPreventionConfigValue = window._env_.hasCheatDetection;
    const hasDetection = getValueFromUrl('nihonium') !== 'ph03n1x';
    if (hasDetection && cheatPreventionConfigValue === 'yes') {
      window.requestAnimationFrame(checkIfDevToolsIsOpen);
    } else {
      dispatch(setDevToolsStatus(false));
    }
  };

  useEffect(() => {
    startDetection();
  }, []);

  return null;
};

export default DetectDevtools;
