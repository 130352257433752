export const GET_DUCS_CONFIG_REQUESTED = 'GET_DUCS_CONFIG_REQUESTED';
export const GET_DUCS_CONFIG_SUCCESS = 'GET_DUCS_CONFIG_SUCCESS';
export const GET_DUCS_CONFIG_ERROR = 'GET_DUCS_CONFIG_ERROR';

export const getDucsConfigRequested = () => ({
  type: GET_DUCS_CONFIG_REQUESTED
});
export const getDucsConfigSuccess = payload => ({
  type: GET_DUCS_CONFIG_SUCCESS,
  ...payload
});
export const getDucsConfigError = error => ({
  type: GET_DUCS_CONFIG_ERROR,
  error
});
export const getDucsConfig = () => async (dispatch) => {
  dispatch(getDucsConfigSuccess({
    url: window._env_.DUCSLibraryUrl,
    config: JSON.parse(window._env_.DUCSConfiguration)
  }));
};
