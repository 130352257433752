import React from 'react';
import { Trans } from '@lingui/macro';
import defaultVoteItemActivation from './info-feedback.constants';

export const getUserFeedbackConfig = () => JSON.parse(window._env_.UserFeedbackConfig);

export const getVoteItems = isTeacher => (
  isTeacher ?
    ['comment'] :
    defaultVoteItemActivation.filter(item => item !== 'comment')
);

export const getTipText = isTeacher => (
  isTeacher ?
    <Trans>{`3P Learning values teacher feedback on this activity.
    Please let us know what you think, or if anything is broken.`}</Trans> :
    <Trans>{`Click on any feedback icon to let Mathletics know how you liked this activity.
    If something isn't working, click on the third icon.`}</Trans>
);

export const handleClick = (initialShowTip, initialActive, withinFeedback, name, activeItem) => {
  let showTip = initialShowTip;
  let active = initialActive;
  if (name === 'cancel') {
    showTip = false;
    active = false;
  } else if (withinFeedback) {
    showTip = false;
    active = initialActive;
  } else if (name && !withinFeedback) {
    if (initialShowTip || initialActive) {
      showTip = false;
      active = false;
    } else if (!initialShowTip && !initialActive) {
      showTip = true;
      active = true;
    }
  } else if (!name && !withinFeedback && activeItem !== 'comment') {
    showTip = false;
    active = false;
  }
  return { showTip, active };
};
