import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactGa from 'react-ga';
import Spinner from '../../components/spinner/spinner';
import ErrorHandling from '../error-handling/error-handling';
import InternetConnectivity from '../internet-connectivity/internet-connectivity';
import Modal from '../modal/modal';
import css from './mle.scss';
import Activity from '../activity/activity';
import StudentProgress from '../student-progress/student-progress';
import Header from '../header/header';
import Footer from '../footer/footer';
import DetectDevtools from '../devtools-detect/devtools-detect';
import DevToolsMessage from '../devtools-detect/devtools-message';
import { getValueFromUrl } from '../../helpers/utils';
import { fetchAssignmentDetails } from './mle-actions';
import DummyOff from '../dummy-off/dummy-off';

const Mle = () => {
  const dispatch = useDispatch();
  const { spinnerReducer, mleReducer } = useSelector(state => state);
  const { isDevToolsOpen } = useSelector(state => state.detectDevToolsReducer);

  useEffect(() => {
    const assignmentId = getValueFromUrl('assignmentId');

    ReactGa.initialize(window._env_.GoogleAnalyticsId);

    ReactGa.event({
      category: 'init',
      action: 'init',
      nonInteraction: true
    });

    dispatch(fetchAssignmentDetails(assignmentId));
  }, []);

  return (
    <Fragment>
      <DetectDevtools />
      <ErrorHandling />
      <Modal />
      <InternetConnectivity />
      <DummyOff />
      {isDevToolsOpen ?
        <DevToolsMessage /> :
        <div className={css.mle}>
          {mleReducer && <Fragment>
            <Header />
            <div className={css.content}>
              <Activity />
              <StudentProgress />
            </div>
            <Footer />
          </Fragment>}
          <Spinner show={spinnerReducer.length > 0 || false} />
        </div>
      }
    </Fragment>
  );
};

export default Mle;
