import { useSelector } from 'react-redux';
import TimerIconWhole from '../../assets/images/timer.svg';
import ArrowWhole from '../../assets/images/arrow.svg';
import TimerIconQuestion from '../../assets/images/timer-white.svg';
import ArrowQuestion from '../../assets/images/arrow-white.svg';
import TimeTicker from '../time-ticker/time-ticker';
import TimeTickerQuestion from '../time-ticker-question/time-ticker-question';

// eslint-disable-next-line import/prefer-default-export
export const useAssets = () => {
  const { durationType } = useSelector(state => state.mleReducer);

  const items = [
    {
      TimeTicker,
      timerIcon: TimerIconWhole,
      arrow: ArrowWhole
    },
    {
      TimeTicker: TimeTickerQuestion,
      timerIcon: TimerIconQuestion,
      arrow: ArrowQuestion
    }
  ];

  const assets = () => items[durationType];

  return [assets];
};
