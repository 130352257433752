import React from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'react-inlinesvg';
import css from './btn-icon.scss';

const BtnIcon = ({
  isDisabled,
  callBack,
  className,
  icon,
  title
}) => (
  <button
    title={title}
    className={`${css.button} ${className}`}
    disabled={isDisabled}
    onClick={callBack}
  >
    <InlineSVG className={css.icon} src={icon} />
  </button>
);

BtnIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  callBack: PropTypes.func,
  title: PropTypes.string
};

export default BtnIcon;
