import { useEffect } from 'react';
import PropTypes from 'prop-types';

const loadedScripts = [];

const LoadScript = ({ url, onLoad, onError }) => {
  useEffect(() => {
    if (!loadedScripts.includes(url)) {
      const scriptElement = window.document.createElement('script');
      scriptElement.src = url;
      scriptElement.onload = onLoad;
      scriptElement.onerror = onError;
      scriptElement.async = true;
      window.document.body.appendChild(scriptElement);
    } else {
      onLoad();
    }
  }, []);
  return null;
};

LoadScript.propTypes = {
  url: PropTypes.string,
  onLoad: PropTypes.func,
  onError: PropTypes.func
};

export default LoadScript;
