import { useState } from 'react';
import { Timer } from 'easytimer.js';
import { TIMED_QUESTION_END_MARKER } from '../../helpers/constants';

const timer = new Timer();

// eslint-disable-next-line import/prefer-default-export
export const useTimer = () => {
  const [duration, setDuration] = useState('');

  const setTick = (seconds) => {
    timer.start({
      precision: 'seconds',
      startValues: { seconds: seconds + 1 },
      countdown: true
    });
    timer.addEventListener('secondsUpdated', () => {
      let str = timer.getTimeValues().toString();
      if (timer.getTimeValues().hours === 0) {
        str = str.slice(3, str.length);
      }
      setDuration(str);
    });

    timer.addEventListener('targetAchieved', () => {
      timer.removeEventListener('secondsUpdated');
      timer.stop();
      setDuration(TIMED_QUESTION_END_MARKER);
    });
  };

  return [duration, setTick];
};
