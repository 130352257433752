import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './btn-text.scss';

const BtnText = ({
  isDisabled,
  children,
  callBack,
  className,
  size = 'large',
  type = 'primary'
}) => (
  <button
    className={classNames(css.button, className, css[size], css[type])}
    disabled={isDisabled}
    onClick={callBack}
  >
    {children}
  </button>
);

BtnText.propTypes = {
  isDisabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  callBack: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string
};

export default BtnText;
