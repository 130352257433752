import Resources from '../../helpers/resources';
import { getValueFromUrl } from '../../helpers/utils';

export const isMarkActivityDisabled = ({ isLoading, userResponse }) => (
  isLoading || !userResponse || !userResponse.isAttempted
);

export const getTextSpeechConfig = async () => {
  return {
    authToken: Resources.env.authToken,
    serviceHostUrl: window._env_.TextToSpeechHostUrl,
    dataUrl: window._env_.TextToSpeechDataUrl,
    locale: getValueFromUrl('locale') || 'en-AU',
    localisationCdnBaseUrl: window._env_.TextToSpeechLocalisationCdnBaseUrl
  };
};

export const canLoadTextToSpeech = ({ canLoadDucs, textSpeechConfig, isLoading, loadedInitialActivity }) =>
  canLoadDucs && textSpeechConfig && (!isLoading || loadedInitialActivity);

export const canLoadKeyboard = ({ showKeyboardIcon, canLoadDucs, isMarked }) =>
  showKeyboardIcon && canLoadDucs && !isMarked;

export const canLoadFlagToggle = ({ canLoadDucs, isCompleted }) => canLoadDucs && !isCompleted;

export const handleMarked = ({ isFetching, isLoading, ducsRef }) => {
  if (!isFetching && !isLoading) {
    ducsRef.current.reload();
  }
};

export const disableInput = ({ isCompleted, isMarked }) => {
  let state;

  if (isCompleted) {
    state = false;
  } else {
    state = isMarked;
  }

  return state;
};
