import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextToSpeech from '@3plearning/tts';
import classNames from 'classnames';
import keyboardIcon from '../../assets/images/ico-keypad.svg';
import css from './activity.scss';
import { BtnIcon } from '../../components/buttons';
import Ducs from '../ducs/ducs';
import {
  getTextSpeechConfig,
  canLoadTextToSpeech,
  canLoadKeyboard,
  canLoadFlagToggle,
  handleMarked,
  disableInput
} from './activity-utils';
import { activateError } from '../error-handling/error-handling-actions';
import {
  handleEvent, handleSpinner
} from './activity-actions';
import FlagToggle from '../flag-toggle/flag-toggle';
import { DUCS_REVIEW, DUCS_RESUME } from '../ducs/ducs-utils';
import { removeLearnosityKeypad } from '../../helpers/utils';
import { useHandleNextFunc } from './activity-hooks';

const Activity = () => {
  const dispatch = useDispatch();
  const {
    activityMeta,
    userResponse,
    isLoading,
    loadedInitialActivity,
    isMarked,
    activityIndex,
    isFetching
  } = useSelector(state => state.activityReducer);
  const { isCompleted } = useSelector(state => state.mleReducer);

  const [textSpeechConfig, setTextSpeechConfig] = useState(null);
  const [isKeyboardActive, setKeyboardActive] = useState(false);
  const [showKeyboardIcon, setShowKeyboardIcon] = useState(false);
  const [handleNextFunc] = useHandleNextFunc();

  const ducsRef = useRef(null);

  const canLoadDucs = activityMeta;

  const showKeyboardEl = (isShow) => {
    const el = window.document.querySelector('.lrn-formula-keyboard');
    if (el) {
      if (isShow && isKeyboardActive) {
        el.classList.remove('hidden');
      } else {
        el.classList.add('hidden');
      }
      if (!isMarked) {
        const focusEl = window.document.querySelector('.js-ducs .lrn_focusable');
        if (focusEl) focusEl.focus();
      }
    }
  };

  useEffect(() => {
    (async () => {
      const config = await getTextSpeechConfig();
      setTextSpeechConfig(config);
    })();
    return () => {
      removeLearnosityKeypad();
    };
  }, []);

  useEffect(() => {
    showKeyboardEl(true);
  }, [isKeyboardActive]);

  useEffect(() => {
    dispatch(handleSpinner({ isLoading, loadedInitialActivity }));
  }, [isLoading]);

  useEffect(() => {
    handleNextFunc(ducsRef);
  }, [activityIndex, isCompleted]);

  useEffect(() => {
    handleMarked({ isFetching, isLoading, ducsRef });
  }, [isMarked]);

  return (
    <div className={css.activity}>
      {canLoadTextToSpeech({ canLoadDucs, textSpeechConfig, isLoading, loadedInitialActivity }) &&
        <TextToSpeech
          reset={!isLoading}
          textSpeechConfig={textSpeechConfig}
          onError={() => { dispatch(activateError({ status: 500 })); }}
          className={css.tts}
        />
      }
      {canLoadKeyboard({ showKeyboardIcon, canLoadDucs, isMarked }) &&
        <BtnIcon
          icon={keyboardIcon}
          className={classNames(css['keyboard-btn'], { [css.active]: isKeyboardActive })}
          callBack={() => setKeyboardActive(!isKeyboardActive)}
        />
      }
      {canLoadFlagToggle({ canLoadDucs, isCompleted }) &&
        <FlagToggle className={css['flag-toggle']} />
      }
      <div className={css['inner-scroll']}>
        {canLoadDucs &&
          <Ducs
            className={classNames({ [css['disable-element']]: disableInput({ isCompleted, isMarked }) })}
            content={activityMeta}
            mode={isCompleted ? DUCS_REVIEW : DUCS_RESUME}
            userResponse={userResponse}
            onEvent={event => dispatch(handleEvent(event, setShowKeyboardIcon, showKeyboardEl))}
            ref={ducsRef}
          />
        }
      </div>
    </div>
  );
};

export default Activity;
