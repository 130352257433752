import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import css from './info.scss';

// eslint-disable-next-line import/prefer-default-export
export const useInfoText = () => {
  const { durationType } = useSelector(state => state.mleReducer);

  const infos = [
    (
      <Fragment>
        <p className={css.para}><Trans>
          If you’re not sure of the answer you can come back and change
          your answer at any point before you hand in your assessment.
        </Trans></p><br />
        <p className={css.para}><Trans>
          TIP:
        </Trans></p>
        <p className={css.para}><Trans>
          Turn on the flag at the top right to bookmark this question
          and return to it later. You can also hide the timer from view.
        </Trans></p>
      </Fragment>
    ),
    (
      <Fragment>
        <p className={css.para}><Trans>
          When your question time limit is up the answer you have written
          will be submitted.
        </Trans></p><br />
        <p className={css.para}><Trans>
          TIP:
        </Trans></p>
        <p className={css.para}><Trans>
          Keep the timer open so you know how much time you have before
          you move onto the next question.
        </Trans></p>
      </Fragment>
    )
  ];
  const infoText = () => infos[durationType];

  return [infoText];
};
