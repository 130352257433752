exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".flag-toggle---2axDX{display:block;height:60px}", ""]);

// exports
exports.locals = {
	"flag-toggle": "flag-toggle---2axDX"
};