import { assignmentDetailsFetched, enableSpinner, disableSpinner } from '../mle/mle-actions';
import Resources from '../../helpers/resources';
import { activateError } from '../error-handling/error-handling-actions';
import { getValueFromUrl, removeLearnosityKeypad } from '../../helpers/utils';
import { DUCS_LOADING, DUCS_LOADED, DUCS_CHANGED, DUCS_ERROR } from '../ducs/ducs-utils';
import { ACTIVITY_META_SPINNER } from '../../helpers/constants';

export const ACTIVITY_META_FETCHED = 'ACTIVITY_META_FETCHED';
export const START_ACTIVITY_META_FETCH = 'START_ACTIVITY_META_FETCH';
export const LOADED_INITIAL_ACTIVITY = 'LOADED_INITIAL_ACTIVITY';
export const ACTIVITY_RESPONSE_SET = 'ACTIVITY_RESPONSE_SET';
export const NEXT_ACTIVITY_SET = 'NEXT_ACTIVITY_SET';
export const LOADING_ACTIVITY_STOPPED = 'LOADING_ACTIVITY_STOPPED';
export const LOADING_ACTIVITY_STARTED = 'LOADING_ACTIVITY_STARTED';
export const IS_MARKED_SET = 'IS_MARKED_SET';
export const ACTIVITY_ACTIVE_SET = 'ACTIVITY_ACTIVE_SET';
export const ACTIVITY_RESET = 'ACTIVITY_RESET';

export const setLoadedInitialActivity = loadedInitialActivity => ({
  type: LOADED_INITIAL_ACTIVITY,
  loadedInitialActivity
});

export const setActivityResponse = userResponse => ({
  type: ACTIVITY_RESPONSE_SET,
  userResponse
});

export const stopLoadingActivity = () => ({
  type: LOADING_ACTIVITY_STOPPED
});

export const startLoadingActivity = () => ({
  type: LOADING_ACTIVITY_STARTED
});

export const setIsMarked = isMarked => ({
  type: IS_MARKED_SET,
  isMarked
});

export const setActivityActive = status => ({
  type: ACTIVITY_ACTIVE_SET,
  status
});

export const resetActivity = () => ({
  type: ACTIVITY_RESET
});

export const setNextQuestion = ({
  index,
  currentIndex,
  totalQuestions
}) => (dispatch) => {
  let nextIndex = index;

  if (!index) {
    if (currentIndex < totalQuestions - 1) {
      nextIndex = currentIndex + 1;
    } else {
      nextIndex = 0;
    }
  }

  dispatch({
    type: NEXT_ACTIVITY_SET,
    nextIndex
  });
};

export const startActivityMetaFetch = () => ({
  type: START_ACTIVITY_META_FETCH
});

export const fetchActivityMeta = ({
  questionId
}) => async (dispatch) => {
  dispatch(startActivityMetaFetch());
  const assignmentId = getValueFromUrl('assignmentId');
  const locale = getValueFromUrl('locale');
  try {
    const questionResponse = await Resources.fetchActivityMeta
      .get({ assignmentId, questionId, locale })
      .send();

    const { status, data } = questionResponse;

    if (status === 200 || status === 204) {
      dispatch({
        type: ACTIVITY_META_FETCHED,
        data
      });
    }
    return questionResponse;
  } catch (e) {
    dispatch(activateError('network error'));
    return e;
  }
};

export const markActivity = ({
  assignmentId,
  questionId,
  isCorrect,
  learnosityData,
  promiseResolved = () => { }
}) => async (dispatch) => {
  try {
    const questionResponse = await Resources.markActivity
      .put({ assignmentId, questionId })
      .withBody({
        isCorrect,
        learnosityData
      })
      .send();

    const { status, data } = questionResponse;

    if (status === 200 || status === 204) {
      dispatch(assignmentDetailsFetched(data));
      promiseResolved();
    }
  } catch (e) {
    dispatch(activateError('network error'));
  }
};

export const flagActivity = ({
  assignmentId,
  questionId,
  isFlagged
}) => async (dispatch) => {
  try {
    const questionResponse = await Resources.flagActivity
      .put({ assignmentId, questionId })
      .withBody({ isFlagged })
      .send();

    const { status, data } = questionResponse;

    if (status === 200 || status === 204) {
      dispatch(assignmentDetailsFetched(data));
    }
  } catch (e) {
    dispatch(activateError('network error'));
  }
};

export const completeAssignment = ({
  assignmentId,
  startFetch = () => { },
  endFetch = () => { }
}) => async (dispatch) => {
  startFetch();
  try {
    const questionResponse = await Resources.completeAssignment
      .put({ assignmentId })
      .withBody({})
      .send();

    const { status, data } = questionResponse;

    if (status === 200 || status === 204) {
      dispatch(setNextQuestion({ index: 0 }));
      dispatch(assignmentDetailsFetched(data));
    }
    endFetch();
  } catch (e) {
    dispatch(activateError('network error'));
    endFetch();
  }
};

export const handleEvent = ({ type, response }, setShowKeyboardIcon, showKeyboardEl) => (dispatch) => {
  switch (type) {
    case DUCS_LOADING:
      removeLearnosityKeypad();
      setShowKeyboardIcon(false);
      dispatch(startLoadingActivity());
      break;
    case DUCS_LOADED:
      if (window.document.querySelector('.lrn-formula-keyboard')) {
        setShowKeyboardIcon(true);
        showKeyboardEl(true);
      }
      dispatch(stopLoadingActivity());
      break;
    case DUCS_CHANGED:
      dispatch(setActivityResponse(response));
      break;
    case DUCS_ERROR:
      dispatch(activateError(500));
      break;
    default:
      // eslint-disable-next-line no-console
      console.error('unknown DUCS event', type, response);
      break;
  }
};

export const handleSpinner = ({ isLoading, loadedInitialActivity }) => (dispatch) => {
  if (!loadedInitialActivity) {
    if (isLoading) {
      dispatch(enableSpinner(ACTIVITY_META_SPINNER));
    } else if (!isLoading) {
      dispatch(disableSpinner(ACTIVITY_META_SPINNER));
      dispatch(setLoadedInitialActivity(true));
    }
  }
};

export const handleNext = ({
  questions,
  activityIndex,
  loadedInitialActivity,
  ducsRef
}) => async (dispatch) => {
  dispatch(startLoadingActivity());
  await dispatch(fetchActivityMeta({
    questionId: questions[activityIndex].girlId
  }));
  const { learnosityData } = questions[activityIndex];
  dispatch(setIsMarked(learnosityData.length > 0));
  dispatch(setActivityResponse(learnosityData));
  if (loadedInitialActivity) {
    ducsRef.current.reload();
  }
};
