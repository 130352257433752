import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './ducs.scss';
import { subscribeToEvents, DUCS_RESUME, DUCS_REVIEW, DUCS_LOADING, DUCS_ERROR } from './ducs-utils';
import { getDucsConfig } from './ducs-actions';
import LoadScript from '../../components/load-script/load-script';

const Ducs = forwardRef(
  (
    {
      content: { locale, girlId, ...pageParams },
      userResponse = {},
      mode,
      activateAutoFocus = false,
      onEvent = () => { },
      className
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const [event, setEvent] = useState(null);
    const [instanceId, setInstanceId] = useState(null);
    const { url, config, error } = useSelector(state => state.ducsReducer);
    const content = pageParams.pageId ? { locale, ...pageParams } : { locale, girlId };

    const initialise = () => {
      const id = window.DUCSApplication.initialise('.js-ducs', {
        configuration: config,
        ...content,
        learnosityInitialState: {
          state: mode,
          useDefaultFeedback: mode === DUCS_REVIEW,
          responses: userResponse === '' ? {} : JSON.parse(userResponse)
        },
        activateAutoFocus
      });
      subscribeToEvents(id, setEvent);
      setInstanceId(id);
    };

    useImperativeHandle(ref, () => ({
      reload: () => {
        if (instanceId && event.type !== DUCS_LOADING) {
          setEvent({ type: DUCS_LOADING });
          window.DUCSApplication.updateOptions(instanceId, {
            ...content,
            learnosityInitialState: {
              state: mode,
              useDefaultFeedback: mode === DUCS_REVIEW,
              responses: userResponse === '' ? {} : JSON.parse(userResponse)
            },
            randomId: Math.random(),
            activateAutoFocus
          });
        }
      }
    }));

    useEffect(() => {
      setEvent({ type: DUCS_LOADING });
      dispatch(getDucsConfig());
    }, []);

    useEffect(() => {
      if (error) {
        setEvent({ type: DUCS_ERROR });
      }
    }, [error]);

    useEffect(() => {
      if (event) {
        onEvent(event);
      }
    }, [event]);

    return (
      <div className={classNames(css.ducs, className)}>
        {url && config && !instanceId && <LoadScript
          url={url}
          onLoad={initialise}
          onError={() => setEvent({ type: DUCS_ERROR })}
        />}
        <div className="js-ducs" />
      </div>
    );
  }
);

Ducs.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.shape({
      pageId: PropTypes.number,
      layoutVersion: PropTypes.number,
      contentVersion: PropTypes.number,
      locale: PropTypes.string
    }),
    PropTypes.shape({
      girlId: PropTypes.string,
      locale: PropTypes.string
    })
  ]).isRequired,
  userResponse: PropTypes.any,
  mode: PropTypes.oneOf([DUCS_RESUME, DUCS_REVIEW]).isRequired,
  activateAutoFocus: PropTypes.bool,
  onEvent: PropTypes.func,
  className: PropTypes.string
};

export default Ducs;
