import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import Pencil from '../../assets/images/pencil.svg';
import Flag from '../../assets/images/flag-small.svg';
import Tick from '../../assets/images/assess-tick.svg';
import Cross from '../../assets/images/assess-cross.svg';
import Dash from '../../assets/images/assess-dash.svg';
import css from './question-icons.scss';
import { useLocalisation } from '../../helpers/i18n/use-localisation';
import {
  UNATTEMPTED,
  ATTEMPTED,
  REVIEW_CORRECT,
  REVIEW_INCORRECT,
  REVIEW_UNATTEMPTED
} from '../../helpers/constants';

const QuestionIcons = ({
  isCompleted,
  question
}) => {
  const { i18n } = useLocalisation();

  const { isAttempted, isFlagged, isCorrect } = question;

  const [firstColumnIcon, setFirstColumnIcon] = useState(UNATTEMPTED);

  useEffect(() => {
    if (isCompleted) {
      if (isCorrect) {
        setFirstColumnIcon(REVIEW_CORRECT);
      } else if (!isCorrect && !isAttempted) {
        setFirstColumnIcon(REVIEW_UNATTEMPTED);
      } else if (!isCorrect && isAttempted) {
        setFirstColumnIcon(REVIEW_INCORRECT);
      }
    } else if (!isCompleted) {
      if (isAttempted) {
        setFirstColumnIcon(ATTEMPTED);
      } else {
        setFirstColumnIcon(UNATTEMPTED);
      }
    }
  }, [isAttempted, isFlagged, isCorrect, isCompleted]);

  return (
    <div className={css['question-icons']}>
      {
        firstColumnIcon === ATTEMPTED &&
          <img
            className={css.pencil}
            src={Pencil}
            alt={i18n._(t`pencil`)}
          />
      }
      {
        firstColumnIcon === REVIEW_CORRECT &&
          <img
            className={css.tick}
            src={Tick}
            alt={i18n._(t`tick`)}
          />
      }
      {
        firstColumnIcon === REVIEW_INCORRECT &&
          <img
            className={css.cross}
            src={Cross}
            alt={i18n._(t`cross`)}
          />
      }
      {
        firstColumnIcon === REVIEW_UNATTEMPTED &&
          <img
            className={css.dash}
            src={Dash}
            alt={i18n._(t`dash`)}
          />
      }
      {
        firstColumnIcon === UNATTEMPTED &&
          <div className={css['empty-column']} />
      }
      {
        isFlagged ?
          <img
            className={css.flag}
            src={Flag}
            alt={i18n._(t`flag`)}
          /> :
          <div className={css['empty-flag']} />
      }
    </div>
  );
};

QuestionIcons.propTypes = {
  isCompleted: PropTypes.bool,
  question: PropTypes.object
};

export default QuestionIcons;
