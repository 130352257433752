exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "@keyframes fade-in---1-qW2{0%{opacity:0}100%{opacity:1}}.time-area---3CFFL{display:flex;align-items:center;cursor:pointer;height:38px}.time-area---3CFFL .timer-icon---3u3FE{display:block}.time-area---3CFFL .time-ticker---1rWY0{margin-left:6px}.time-area---3CFFL .arrow---3d1we{display:block;margin-left:6px}.time-area---3CFFL.min---10qFj .time-ticker---1rWY0{display:none}.time-area---3CFFL.min---10qFj .arrow---3d1we{transform:rotate(180deg)}@media only screen and (max-width: 800px){.time-area---3CFFL .timer-icon---3u3FE{display:none}.time-area---3CFFL.min---10qFj .timer-icon---3u3FE{display:block}}", ""]);

// exports
exports.locals = {
	"time-area": "time-area---3CFFL",
	"timer-icon": "timer-icon---3u3FE",
	"time-ticker": "time-ticker---1rWY0",
	"arrow": "arrow---3d1we",
	"min": "min---10qFj",
	"fade-in": "fade-in---1-qW2"
};