exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "@keyframes fade-in---3J-Zg{0%{opacity:0}100%{opacity:1}}.footer---29rdj{animation:fade-in---3J-Zg 1s ease-in;height:80px;display:flex;align-items:flex-start;z-index:100;max-width:1200px;width:100%;margin:0 auto}.footer---29rdj .activity-side---2N8uv{display:flex;justify-content:space-between;width:calc(100% - 264px);margin:0 5px 0 40px}.footer---29rdj .activity-side---2N8uv .misc-container---38GNw{z-index:1;display:flex;align-items:center}.footer---29rdj .activity-side---2N8uv .misc-container---38GNw .info-feedback-margin---2g-_Z{margin-left:20px}.footer---29rdj .activity-side---2N8uv .btn-cont---RjunB{display:flex;margin-left:20px}.footer---29rdj .progress-side---1EpzM{width:174px;margin:0 40px 0 5px;display:flex;justify-content:center}.footer---29rdj.question---11x3d .btn-cont---RjunB{display:none}.footer---29rdj.question---11x3d .progress-side---1EpzM .btn---2XOHi{display:none}", ""]);

// exports
exports.locals = {
	"footer": "footer---29rdj",
	"fade-in": "fade-in---3J-Zg",
	"activity-side": "activity-side---2N8uv",
	"misc-container": "misc-container---38GNw",
	"info-feedback-margin": "info-feedback-margin---2g-_Z",
	"btn-cont": "btn-cont---RjunB",
	"progress-side": "progress-side---1EpzM",
	"question": "question---11x3d",
	"btn": "btn---2XOHi"
};