import React from 'react';
import css from './student-progress.scss';
import ProgressInfo from '../progress-info/progress-info';
import Questions from '../questions/questions';

const StudentProgress = () => (
  <div className={css['student-progress']}>
    <ProgressInfo />
    <Questions />
  </div>
);

export default StudentProgress;
