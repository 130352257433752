import {
  ACTIVITY_META_FETCHED,
  LOADING_ACTIVITY_STOPPED,
  LOADING_ACTIVITY_STARTED,
  ACTIVITY_RESPONSE_SET,
  NEXT_ACTIVITY_SET,
  IS_MARKED_SET,
  ACTIVITY_ACTIVE_SET,
  ACTIVITY_RESET,
  START_ACTIVITY_META_FETCH,
  LOADED_INITIAL_ACTIVITY
} from './activity-actions';

const initialState = {
  activityIndex: 0,
  activityMeta: null,
  userResponse: '',
  isLoading: true,
  isFetching: true,
  loadedInitialActivity: false,
  isMarked: false,
  isActivityActive: false
};

const activityReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_ACTIVITY_META_FETCH:
      return {
        ...state,
        isFetching: true
      };

    case ACTIVITY_META_FETCHED:
      return {
        ...state,
        isFetching: false,
        activityMeta: action.data
      };

    case LOADING_ACTIVITY_STOPPED:
      return {
        ...state,
        isLoading: false
      };

    case LOADING_ACTIVITY_STARTED:
      return {
        ...state,
        isLoading: true
      };

    case LOADED_INITIAL_ACTIVITY:
      return {
        ...state,
        loadedInitialActivity: action.loadedInitialActivity
      };

    case ACTIVITY_RESPONSE_SET:
      return {
        ...state,
        userResponse: action.userResponse
      };

    case NEXT_ACTIVITY_SET:
      return {
        ...state,
        activityIndex: action.nextIndex
      };

    case IS_MARKED_SET:
      return {
        ...state,
        isMarked: action.isMarked
      };

    case ACTIVITY_ACTIVE_SET:
      return {
        ...state,
        isActivityActive: action.status
      };

    case ACTIVITY_RESET:
      return {
        ...state,
        loadedInitialActivity: false,
        isLoading: true,
        isFetching: true
      };

    default:
      return state;
  }
};

export default activityReducer;
