exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "@keyframes fade-in---1WC92{0%{opacity:0}100%{opacity:1}}.progress-info---4BpsR{height:110px;padding:10px}.progress-info---4BpsR .progress-text---1d3Xf{margin-top:6px}.progress-info---4BpsR .progress-text---1d3Xf span{font-size:13px}.progress-info---4BpsR .progress-bar---2sWLc{width:100%;height:16px;background-color:rgba(0,0,0,0);border-radius:16px;margin-top:6px;overflow:hidden}.progress-info---4BpsR .progress-bar---2sWLc .inner-bar---2x4IG{width:0;height:16px}.progress-info---4BpsR.whole---1onWo{background-color:#a6e3e4}.progress-info---4BpsR.whole---1onWo .progress-bar---2sWLc{border:2px solid #3477c7}.progress-info---4BpsR.whole---1onWo .progress-bar---2sWLc .inner-bar---2x4IG{background-color:#3477c7}.progress-info---4BpsR.question---24pup{background-color:#fd7849}.progress-info---4BpsR.question---24pup .progress-text---1d3Xf{color:#fff}.progress-info---4BpsR.question---24pup .progress-bar---2sWLc{border:2px solid #fff}.progress-info---4BpsR.question---24pup .progress-bar---2sWLc .inner-bar---2x4IG{background-color:#fff}@media only screen and (max-width: 800px){.progress-info---4BpsR .progress-text---1d3Xf .answered---1I2bV{display:none}}", ""]);

// exports
exports.locals = {
	"progress-info": "progress-info---4BpsR",
	"progress-text": "progress-text---1d3Xf",
	"progress-bar": "progress-bar---2sWLc",
	"inner-bar": "inner-bar---2x4IG",
	"whole": "whole---1onWo",
	"question": "question---24pup",
	"answered": "answered---1I2bV",
	"fade-in": "fade-in---1WC92"
};