exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "@keyframes fade-in---tiNRC{0%{opacity:0}100%{opacity:1}}.student-progress---2QTg3{animation:fade-in---tiNRC 1s ease-in;width:174px;margin:0 40px 0 5px;display:flex;flex-direction:column;border-radius:16px;z-index:100;transition:all .5s ease-out;position:relative;overflow:hidden;background-color:#3477c7}@media only screen and (max-width: 800px){.student-progress---2QTg3{position:absolute;right:0;margin:0;width:105px;height:100%}}", ""]);

// exports
exports.locals = {
	"student-progress": "student-progress---2QTg3",
	"fade-in": "fade-in---tiNRC"
};