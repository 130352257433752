exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".time-ticker---2NXJf{color:#fff;display:block;font-weight:bold}", ""]);

// exports
exports.locals = {
	"time-ticker": "time-ticker---2NXJf"
};