import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import iconQuestion from '../../assets/images/icon_questionmark.svg';
import css from './info.scss';
import InfoIcon from '../../components/icon-info/info-icon';
import { setActivityActive } from '../activity/activity-actions';
import { useInfoText } from './info-hooks';

const Info = () => {
  const dispatch = useDispatch();
  const [showTip, setShowTip] = useState(false);
  const [infoText] = useInfoText();

  const infoIconData = {
    icon: iconQuestion,
    paragraph: infoText(),
    showTip,
    onClick: e => setShowTip(e ? !showTip : false)
  };

  useEffect(() => {
    dispatch(setActivityActive(!showTip));
  }, [showTip]);

  return (
    <InfoIcon
      className={classNames(css['info-icon'], { [css['info-icon-active']]: showTip })}
      infoIconData={infoIconData}
    />
  );
};


export default Info;
