import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { t } from '@lingui/macro';
import logo from '../../assets/images/mathletics-logo.svg';
import backIcon from '../../assets/images/back-icon.svg';
import css from './header.scss';
import { BtnIcon } from '../../components/buttons';
import Profile from '../profile/profile';
import { useLocalisation } from '../../helpers/i18n/use-localisation';
import { leaveMle, getValueFromUrl } from '../../helpers/utils';
import { useBackModal } from './header-hooks';

const Header = () => {
  const { i18n } = useLocalisation();
  const {
    name,
    student,
    isCompleted
  } = useSelector(state => state.mleReducer);

  const [backModal] = useBackModal();

  const handleBack = () => {
    const isTeacher = getValueFromUrl('isPreview');
    if (isCompleted || isTeacher) {
      leaveMle('Button - back');
      return;
    }

    backModal();
  };

  return (
    <div className={css.header}>
      <div className={css.left}>
        <BtnIcon
          title={i18n._(t`Back to Student Console`)}
          icon={backIcon}
          className={css.back}
          callBack={handleBack}
        />
        <BtnIcon icon={logo} className={css.logo} />
        <p>{name}</p>
      </div>
      <div className={classNames(css.right, { [css['no-collapse']]: student.firstName.length <= 10 })}>
        <Profile firstName={student.firstName} />
      </div>
    </div>
  );
};

export default Header;
