// eslint-disable-next-line import/prefer-default-export
export const countAnswered = (questions) => {
  let counter = 0;

  questions.forEach((question) => {
    if (question.isAttempted) {
      counter += 1;
    }
  });

  return counter;
};
